import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';
import firebase from 'firebase/app';
// import { sgAddUserToMailingList, sgUpdateUserDetails, sgSearchUserByUID } from '../sendGrid/sendGrid';
import { createCampaign } from './FirestoreContext';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';

const AuthContext = createContext();

// hook to access AuthContext
export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {

	// authUser connected to FB Auth user state (email)
	// fsUser connected to FB Firestore user state (all other data)
	const [authUser, setAuthUser] = useState();
	const [fsUser, setFsUser] = useState();
	const [loading, setLoading] = useState(true);
	const [hitEntryLimit, setHitEntryLimit] = useState(false);
	const [hitEditLimit, setHitEditLimit] = useState(false);
	const [hitPageLimit, setHitPageLimit] = useState(false);
	const [hitCampaignLimit, setHitCampaignLimit] = useState(false);
	const [hitUploadLimit, setHitUploadLimit] = useState(false);
	const [showLimitModal, setShowLimitModal] = useState(false);

	function addUserToFirestore(user, name = null) {
		const collection = firestore.collection('users');
		try {
			if (user.uid) {
				collection.doc(user.uid).get().then(async (snapshot) => {
					if (!snapshot.exists) {
						// Only set FS details and mailing list if user doesn't exist
						const details = {
							displayName: user.displayName || name, // displayName = Google, name = form
							picture: user.photoURL || null,
							premium: false,
							uid: user.uid,
							email: user.email,
							prevCampaign: user.uid,
							memberSince: moment().unix(),
							weeklyEntriesMade: 0,
							weeklyEditsMade: 0,
							weeklyPagesMade: 0,
							weeklyUploadBytes: 0,
							weeklyRefreshDate: moment().unix() + 604800
						};
						// sgAddUserToMailingList(user.email, user.displayName || name, user.uid); // SendGrid
						await createCampaign('My notes', user.uid, true); // true = onUserCreation
						return collection.doc(user.uid).set(details);
					}
				});
			} else {
				Sentry.captureMessage("DIDN'T CREATE FS USER - !user.uid");
			}
		} catch (error) {
			Sentry.captureException(error);
		}

	}

	async function signup(email, password, name) {
		const user = await auth.createUserWithEmailAndPassword(email, password);
		auth.currentUser.sendEmailVerification();
		addUserToFirestore(auth.currentUser, name);
		return user;
	}

	async function login(email, password) {
		auth.useDeviceLanguage();
		return auth.signInWithEmailAndPassword(email, password);
	}

	async function googleLogin(provider) {
		auth.useDeviceLanguage();
		auth.signInWithRedirect(provider);
		// signInWithRedirect requires same authDomain as custom domain name
		// auth.signInWithPopup(provider);
		// addUserToFirestore when called in LandingPage
	}

	function logout() {
		return auth.signOut();
	}

	function resetPassword(email) {
		return auth.sendPasswordResetEmail(email);
	}

	async function updateEmail(email) {
		// Update Firestore
		await auth.currentUser.updateEmail(email);
		auth.currentUser.sendEmailVerification();
		// Update SG, done first so throws error if user doesn't exist yet
		// const sgUser = await sgSearchUserByUID(auth.currentUser.uid);
		// const sgOriginalEmail = await sgUser.result[0].email;
		// sgUpdateUserDetails({
		// 	"email": sgOriginalEmail,
		// 	"alternate_emails": [email]
		// });
	}

	async function updateDisplayName(name) {
		// Update the firestore display name, don't use the auth display name
		await firestore.collection('users').doc(auth.currentUser.uid).update({
			displayName: name
		});
		// Update SG
		// const sgUser = await sgSearchUserByUID(auth.currentUser.uid);
		// const sgOriginalEmail = await sgUser.result[0].email;
		// sgUpdateUserDetails({
		// 	"email": sgOriginalEmail,
		// 	"first_name": name,
		// 	"custom_fields": {
		// 		"e6_T": name,
		// 	}
		// });
	}

	useEffect(() => {
		// when firebase creates user, set current user when component mounted
		const unsubscribe = auth.onAuthStateChanged((user) => {
			// firebase sets local storage and tokens so it can verify if a user
			// is already signed in
			setAuthUser(user);
			setLoading(false);
		});

		// return to stop listening for changes
		return unsubscribe;
	}, []);

	useEffect(() => {
		// Subscription details are synced to the subscriptions sub-collection in the user’s corresponding customer doc.
		if (fsUser) {
			const unsubscribe = firestore.collection('users')
				.doc(fsUser.uid)
				.collection('subscriptions')
				.where('status', 'in', ['trialing', 'active'])
				.onSnapshot(async (snapshot) => {
					// Paid user
					if (!snapshot.empty) {
						// const doc = snapshot.docs[0];
						// Set user to premium, reset weekly limits for if they become a free user again
						firestore.collection('users').doc(fsUser.uid).update({
							premium: true,
							weeklyEntriesMade: 0,
							weeklyEditsMade: 0,
							weeklyPagesMade: 0
							// not uploadSize as that is still used for max capacity
						});
						// Update SendGrid paid users list
						// sgUpdateUserDetails({
						// 	"email": fsUser.email,
						// 	"custom_fields": {
						// 		"e7_T": "true",
						// 	}
						// });
					} else { // Free user
						// Testers get premium for free
						firestore.collection('users').doc(fsUser.uid).update({ premium: fsUser.tester ? true : false });
						// Update SendGrid free users list
						// sgUpdateUserDetails({
						// 	"email": fsUser.email,
						// 	"custom_fields": {
						// 		"e7_T": "false",
						// 	}
						// });
					}
				});

			return unsubscribe;
		}
	}, [fsUser]);

	useEffect(() => {
		// listen for when auth state changes (user signs in/out)
		if (authUser) {
			// get the user's firestore data and set state
			const unsubscribe = firestore.collection('users').doc(authUser.uid).onSnapshot((doc) => {
				const user = { ...doc.data() };
				user.uid && setFsUser(user);
				// don't set fsUser until auth and stripe user have merged
				// otherwise stripe user is first and fsUser has no uid and causes error
			});
			// return to stop listening for changes
			return unsubscribe;
		} else {
			setFsUser(null);
		}
	}, [authUser]);

	useEffect(() => {
		// Check if they go over their free weekly usage
		if (fsUser && fsUser.uid && !fsUser.premium) {
			const fsUserRef = firestore.collection('users').doc(fsUser.uid);
			// Update weekly refresh date
			fsUser.weeklyRefreshDate ?
				// If past refresh date, add week to current time - the amount of week remaining
				(moment().unix() > fsUser.weeklyRefreshDate) &&
				fsUserRef.update({
					weeklyRefreshDate: moment().unix() + 604800 - (fsUser.weeklyRefreshDate % 604800),
					weeklyEntriesMade: 0,
					weeklyEditsMade: 0,
					weeklyPagesMade: 0,
					weeklyUploadBytes: 0
				})
				:
				fsUserRef.update({
					weeklyRefreshDate: moment().unix(),
					memberSince: moment().unix()
					// lets old users now get the free premium week when they now revisit
					// memberSince should no longer be a fs ts, but changed to unix number
				});

			// If user reaches free limit show paywall
			// but give them 1 week of unlimited use (except for campaigns and uploads)
			if (fsUser.weeklyEntriesMade) {
				(fsUser.weeklyEntriesMade >= 100)
					&& (moment().unix() > (fsUser.memberSince + 604800))
					&& reachedFreeLimit('entries');
			}
			if (fsUser.weeklyEditsMade) {
				(fsUser.weeklyEditsMade >= 25)
					&& (moment().unix() > (fsUser.memberSince + 604800))
					&& reachedFreeLimit('edits');
			}
			if (fsUser.weeklyPagesMade) {
				(fsUser.weeklyPagesMade >= 25)
					&& (moment().unix() > (fsUser.memberSince + 604800))
					&& reachedFreeLimit('pages');
			}
			if (fsUser.sharedCampaigns) {
				(fsUser.sharedCampaigns >= 1) && reachedFreeLimit('campaigns');
			}
			if (fsUser.weeklyUploadBytes) {
				(fsUser.weeklyUploadBytes >= 5000000) && reachedFreeLimit('uploads'); // 5 MB
				// 10,000,000 Bytes = 10,000 KB = 10 MB = 0.01 GB
			}
		}
	}, [fsUser]);

	const reachedFreeLimit = (unit) => {
		switch (unit) {
			case 'entries':
				setHitEntryLimit(true);
				setShowLimitModal(true);
				break;
			case 'edits':
				setHitEditLimit(true);
				setShowLimitModal(true);
				break;
			case 'pages':
				setHitPageLimit(true);
				setShowLimitModal(true);
				break;
			case 'campaigns':
				// Don't show limit modal after only having joined 1 campaign
				setHitCampaignLimit(true);
				break;
			case 'uploads':
				setHitUploadLimit(true);
				setShowLimitModal(true);
				break;
			default:
				break;
		}
	};

	const goToCheckout = async (stripePriceId) => {
		return firestore.collection('users').doc(authUser.uid).collection('checkout_sessions').add({
			price: stripePriceId, // stripe product price id
			success_url: window.location.origin, // return user back to this screen
			cancel_url: window.location.origin, // return user back to this screen
			allow_promotion_codes: true,
		}).then((docRef) => {
			// Wait for the CheckoutSession to get attached by the stripe extension
			docRef.onSnapshot(async (snap) => {
				const { sessionId } = snap.data();
				if (sessionId) {
					// session created, initialize stripe
					const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
					await stripe.redirectToCheckout({ sessionId });
				}
			});
		});
	};

	const goToStripePortal = async () => {
		const functionRef = firebase.app().functions('europe-west2') // need to include region
			.httpsCallable(
				'ext-firestore-stripe-subscriptions-createPortalLink');
		const { data } = await functionRef({ returnUrl: window.location.origin });
		window.location.assign(data.url);
	};

	// expose values anywhere within app
	const value = {
		authUser,
		fsUser,
		signup,
		login,
		logout,
		resetPassword,
		updateEmail,
		updateDisplayName,
		googleLogin,
		hitEntryLimit,
		hitEditLimit,
		hitPageLimit,
		hitCampaignLimit,
		hitUploadLimit,
		showLimitModal,
		setShowLimitModal,
		goToCheckout,
		goToStripePortal,
		addUserToFirestore
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
